import React, { Component } from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {PopupScreen} from '../UiElements';
import selphoIcon from './images/selpho-icon.png';

import './limit.scss';

class Limit extends Component {
    render() {
        const {t, event} = this.props;
        
        if(!this.props.show || !event) return null;

        return (
            <div id="limit-popup">
                <PopupScreen showCloseButton={true} onClose={this.props.onClose}>
                    <div className="limit-wrapper">
                        <img className="selpho-icon" src={selphoIcon} alt="SELPHO"/>
                        <div className="limit-title">
                        אופס...
                        </div>
                        <div className="limit-subtitle">
                        ניצלת את כל מכסת התמונות
                        לאירוע, נפגש באירוע הבא!
                        </div>
                    </div>
                </PopupScreen>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.eventReducer
    }
}

const LimitWithRedux = connect(mapStateToProps)(Limit);
const LimitWithT = withTranslation()(LimitWithRedux);

export {LimitWithT as Limit};