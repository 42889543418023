import React, { Component } from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {CameraActions, EventActions} from '../../actions';
import {Upload as UploadPopup, Limit as LimitPopup, ErrorPopup, BlockedPopup, HorizontalScreen} from './';
import {Pic, Flip, Upload, FilterList, Submit, Trash, Sticker, StickerList} from './buttons';
import './camera.scss';

class Camera extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showFilters: false,
            showStickers: false,
            showLimitPopup: true,
            showErrorPopup: true
        }   

        this.canvas = React.createRef();

        this.onUploadFromDevice = this.onUploadFromDevice.bind(this);
        this.toggleFilterList = this.toggleFilterList.bind(this);
        this.toggleStickerList = this.toggleStickerList.bind(this);
        this.onPic = this.onPic.bind(this);
        this.onFlip = this.onFlip.bind(this);
        this.onTrash = this.onTrash.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.closeLimitPopup = this.closeLimitPopup.bind(this);
    }
    
    componentDidMount() {
        this.initCamera();
    }

    componentDidUpdate() {
        if(!CameraActions.isInit()) this.initCamera();
    }

    componentWillUnmount() {
        CameraActions.stop();
    }

    initCamera() {
        const {event, match} = this.props;
        this.props.dispatch(CameraActions.init(event, this.canvas.current, match.params.shortenUrl));
    }

    onUploadFromDevice() {
        this.closeFiltersAndStickers();
        this.props.dispatch(CameraActions.upload());
    }

    onPic() {    
        this.closeFiltersAndStickers();
        this.props.dispatch(CameraActions.pic());
    }

    onFlip() {
        this.closeFiltersAndStickers();
        this.props.dispatch(CameraActions.flip());
    }

    onTrash() {
        this.closeFiltersAndStickers();
        this.props.dispatch(CameraActions.restart());
    }

    onSubmit() {
        const {dispatch, camera, event} = this.props;
        
        this.closeFiltersAndStickers();
        dispatch(EventActions.getEventData()); // validate user can send image (maybe event alraedy closed);

        if(event.closed) return;
        if(event.maxImages && event.maxImages.user <= 0) {
            return this.setState({showLimitPopup: true});
        }

        const blob = CameraActions.getBlob();

        dispatch(EventActions.postImage({
            image: blob,
            copies: camera.copies
        }))
    }

    onDownload() {
        this.closeFiltersAndStickers();
        CameraActions.download(this.props.event.title);
    }

    closeFiltersAndStickers() {
        this.setState({showFilters: false, showStickers: false});
    }

    toggleFilterList() {
        this.setState({showStickers: false, showFilters: !this.state.showFilters});
    }

    toggleStickerList() {
        this.setState({showFilters: false, showStickers: !this.state.showStickers});
    }

    closeLimitPopup() {
        this.setState({showLimitPopup: false});
    }

    render() {
        const {level} = this.props.camera;
        const {event} = this.props;
        const {showLimitPopup, showFilters, showStickers} = this.state;

        return (
            <div id="camera">
                <div id="camera-wrapper">
                        <canvas ref={this.canvas}></canvas>
                </div>
                <div className="camera-bottom-button">
                    <div className={`buttons buttons-before-pic ${level === 1 ? 'active': ''}`}>
                        <div className="buttons-group buttons-group-right">
                            <Flip onClick={this.onFlip}/>
                        </div>
                        <Pic onClick={this.onPic}/>
                        <div className="buttons-group buttons-group-left">
                            <Upload onClick={this.onUploadFromDevice}/>
                        </div>
                    </div>
                    <div className={`buttons buttons-after-pic pad ${level === 2 ? 'active': ''}`}>
                        <div className="buttons-group buttons-group-left buttons-group-flex">
                            <Trash onClick={this.onTrash} />
                            <Sticker onClick={this.toggleStickerList}/>
                        </div>
                    </div>
                    <div className={`buttons buttons-after-pic pad2 ${level === 2 ? 'active': ''}`}>
                        <div className="buttons-group buttons-group-right">
                            <Submit onClick={this.onSubmit} />
                        </div>
                    </div>
                    <FilterList show={showFilters}/>
                    <StickerList onStickerClick={this.toggleStickerList} show={showStickers} onClose={this.toggleStickerList}/>
                </div>
                <UploadPopup />
                <LimitPopup show={event.maxImages && event.maxImages.user <= 0 && showLimitPopup} onClose={this.closeLimitPopup}/>
                <BlockedPopup />
                <ErrorPopup />
                <HorizontalScreen />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.eventReducer,
        camera: state.cameraReducer
    }
}

const CameraWithRedux = connect(mapStateToProps)(Camera);
const CameraWithT = withTranslation()(CameraWithRedux);

export {CameraWithT as Camera};



