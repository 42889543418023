import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {MainScreen} from '../UiElements';
import './welcome.scss';


class Welcome extends Component {
    constructor(props) {
        super(props);

        this.renderClientLogo = this.renderClientLogo.bind(this);
    }

    renderClientLogo() {
        const {logo} = this.props.event;
        if(!logo) return null;
        return (
            <div 
                className="client-logo" 
                style={{backgroundImage: `url(${logo})`}}>
            </div>
        )
    }

    render() {
        const {title, cover} = this.props.event;

        if (!title) {
            return null;
        }

        return (
            <MainScreen headerElement={this.renderClientLogo()}>
                <div className="open-camera-wrapper">
                    <div className='home-img-container' style={{backgroundImage: `url(${cover && cover.url ? cover.url : '/home.png'})`}}>
                        {/* <img className='home-img' src="/home.png" alt="" /> */}
                    </div>
                    <div className="event-title">
                        {title}
                    </div>
                    <p className='desc-text-bold'>
                            היי, איזה יפים אתם!
                    </p>
                    <div className='desc-text-container'>
                    <p>
                        כאן מצטלמים או מעלים תמונה מהגלריה שלכם,
                        ואנחנו נדאג למזכרת מהחלומות :)
                        (תוכלו לאסוף אותה מהעמדה)
                    </p>
                    </div>
                    <div className='link-cont'>
                        <Link to={`${this.props.match.url.replace(/\/$/g, '')}/cam`} className="open-camera">
                        הבנתי, בואו נתחיל!
                        </Link>
                    </div>
                </div>
            </MainScreen>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.eventReducer
    }
}

const WelcomeWithRedux = connect(mapStateToProps)(Welcome);

export {WelcomeWithRedux as Welcome}