import React, { Component } from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {PopupScreen} from '../UiElements';
import {uploadConstants} from '../../constants';
import errorIcon from './images/errorimg.svg';

import './error.scss';

class ErrorPopup extends Component {
    onClose() {
        this.props.dispatch({type: uploadConstants.RESET_UPLOAD});
    }

    render() {
        const {upload} = this.props;
        
        if(!upload || !upload.uploadError) return null;



        return (
            <div id="error-popup">
                <PopupScreen showCloseButton={true} onClose={this.onClose.bind(this)}>
                    <div className="error-wrapper">
                        <img className="error-icon" src={errorIcon} alt="Error"/>
                        <div className="error-title">
                        אירעה שגיאה.
                        </div>
                        <div className="error-subtitle">
                        גם זה קורה.. נסו שוב!
                        </div>
                    </div>
                </PopupScreen>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.eventReducer,
        upload: state.uploadReducer
    }
}

const ErrorWithRedux = connect(mapStateToProps)(ErrorPopup);
const ErrorWithT = withTranslation()(ErrorWithRedux);

export {ErrorWithT as ErrorPopup};