import React, { Component } from 'react';
import { StickerActions } from '../../../../actions';
import { StickerItem } from './';
import './stickerList.scss';
import del from './images/del.svg';

class StickerList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stickersUrl: [],
            defaultStickers: {
                url: '/images/stickers/',
                ex: 'svg',
                start: 1,
                end: 51,
                prefix: ''
            }
        };
    }

    componentDidMount() {
        this.setState({
            stickersUrl: StickerActions.getStickersUrls(this.state.defaultStickers)
        });
    }

    renderStickerList() {
        return this.state.stickersUrl.map((url, i) => (
            <StickerItem onClick={this.props.onStickerClick} src={url} key={i} />
        ));
    }

    render() {
        if (!this.props.show) return null;

        return (
            <div id="camera-sticker-list">
                <div
                    className="del"
                    onClick={() => {
                        console.log('Кнопка закрытия нажата');
                        if (this.props.onClose) {
                            this.props.onClose();
                        } else {
                            console.error('Проп onClose не передан');
                        }
                    }}
                >
                    <img src={del} alt="Удалить" />
                </div>
                <div className="stickers-wrapper">
                    {this.renderStickerList()}
                </div>
            </div>
        );
    }
}

export { StickerList };