import React, { Component } from 'react';
import './popupScreen.scss';
import del from './images/del.svg';

class PopupScreen extends Component {
    render() {
        return (
            <div className="popup-screen">
                <div className="popup-area">
                    <div className={`popup-close ${this.props.showCloseButton ? 'show' : 'hide'}`} onClick={this.props.onClose}>
                        <img className='del' src={del} alt="" />
                    </div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export {PopupScreen}