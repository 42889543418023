import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const {REACT_APP_DEFAULT_LANG} = process.env;

i18n
    .use(initReactI18next)
    .init({
        interpolation: {
            // React already does escaping (safes from xss)
            escapeValue: false,
        },
        lng: REACT_APP_DEFAULT_LANG,
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    termsOfUse: 'Terms of Use',
                    eventClosed: 'Event Closed',
                    eventFinished: 'Event Finished',
                    eventWaiting: '',
                    submit: 'Submit',
                    copies: 'Copies',
                    onUploadImage: 'Uploading...',
                    onSuccessUploadFirstLine: '',
                    onSuccessUploadSecondLine: '',
                    onSuccessButton: '',
                    imageCounter: '',
                    limitBigTitle: '',
                    limitSubTitle: '',
                    errorBigTitle: '',
                    errorSubTitle: '',
                    blockedBigTitle: '',
                    blockedSubTitle: '',
                    page404Title: '',
                    horizontalScreenWarning: ''
                },
            },
            he: {
                translation: {
                    termsOfUse: 'תנאי שימוש',
                    eventClosed: 'האירוע סגור',
                    eventFinished: 'האירוע הסתיים!',
                    eventWaiting: 'האירוע עדיין לא נפתח',
                    submit: 'אהבתי, בואו נדפיס!',
                    copies: 'עותקים',
                    onUploadImage: 'התמונה נשלחת אנא המתינו...',
                    onSuccessUpload: 'התמונה שלך מוכנה!',
                    onSuccessUploadSecondLine: 'ומחכה לך בעמדת הממו שלנו :) נשארו לך עוד 4 תמונות להדפיס',
                    onSuccessButton: 'יצא פצצה, בא לי עוד!',
                    imageCounter: 'נותרו לך {{number}} תמונות לצלם',
                    limitBigTitle: 'שמחים שנהנתם!',
                    limitSubTitle: 'הגעתם למכסת התמונות שנקבעה',
                    errorBigTitle: 'ארעה שגיאה!',
                    errorSubTitle: 'גם זה קורה, אנא נסו שנית!',
                    blockedBigTitle: 'אופס!',
                    blockedSubTitle: 'לא ניתן להעלות תמונה מלפני תחילת האירוע',
                    page404Title: 'האירוע לא נמצא',
                    horizontalScreenWarning: 'אנא הפכו את המכשיר'
                },
            },
        },
    })
export default i18n;