import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import './submit.scss';

class Submit extends Component {
    render() {
        return (
            <div id="camera-submit-btn" onClick={this.props.onClick}>
                {this.props.t('submit')}
            </div>
        )
    }
}

const SubmitWithT = withTranslation()(Submit);

export {SubmitWithT as Submit}